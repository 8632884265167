import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './Cover.module.scss';
import { motion, useAnimation } from "framer-motion";

function Cover(props) {
  const controls = useAnimation();
  const [sizeAndPosition, setSizeAndPosition] = useState([]);
  const breakpoints = useSelector(state => state.browser.is);
  let breakpoint = Object.keys(breakpoints).find(key => breakpoints[key] === true);

  const motionVariantsT = {
    "initialT": {
      left: breakpoint === 'small' ? 'calc(50% - 4px)' : 'calc(50% - 7px)',
      transform: 'translateX(-100%) translateY(-50%) translateZ(0)',
    },
    "expandedT": {
      left: 0,
      transform: 'translateX(0%) translateY(-50%) translateZ(0)',
      transition: {
        duration: 0.8,
        ease: [0.25, 0.1, 0.25, 1]
      }
    }
  };

  const motionVariantsY = {
    "initialY": {
      left: breakpoint === 'small' ? 'calc(50% + 4px)' : 'calc(50% + 7px)',
      transform: 'translateX(0) translateY(-50%) translateZ(0)',
    },
    "expandedY": {
      left: '100%',
      transform: 'translateX(-100%) translateY(-50%) translateZ(0)',
      transition: {
        duration: 0.8,
        ease: [0.25, 0.1, 0.25, 1]
      }
    }
  };

  useEffect(() => {
    if (false && props.firstVisitOnHome && props.preloadStatus === 'idle') {
      controls.start((i) => ({
        opacity: 0,
        x: 100,
        transition: { delay: i * 0.8 }
      }));
    }
  }, [props.firstVisitOnHome, props.preloadStatus, controls]);

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function randomizeSizeAndPosition(aspectRatio) {
    let breakpoint = Object.keys(breakpoints).find(key => breakpoints[key] === true);
    let offset = breakpoint === 'small' ? 40 : 50;
    let smallSize = aspectRatio !== 'portrait' ? 0.66 : 0.83;
    return {
      transform: `scale(${breakpoint === 'small' ? smallSize : 0.85}) translateX(${getRandomInt(offset * -1, offset)}px) translateY(${getRandomInt(offset * -1, offset)}px)`
    }
  }

  function generateSizeAndPosition() {
    let breakpoint = Object.keys(breakpoints).find(key => breakpoints[key] === true);
    const arr = [];
    for (let i = 0; i < props.homeImages.length; i++) {
      if (i !== props.homeImages.length - 1) {
        arr.push(randomizeSizeAndPosition(props.homeImages[i].aspectRatio));
      } else {
        let smallSize = props.homeImages[i].aspectRatio !== 'portrait' ? 0.66 : 0.83;
        arr.push({
          transform: `scale(${breakpoint === 'small' ? smallSize : 0.85})`
        });
      }

    }

    setSizeAndPosition(arr);
  }

  function renderHomeImages() {
    if (!sizeAndPosition.length) {
      generateSizeAndPosition();
    }

    return [...props.homeImages].reverse().map((img, i) => {
      return <div key={`homeImage-${i}`} className={img.aspectRatio === 'portrait' ? styles.homeImagePortrait : styles.homeImage}>
        <div className={styles.heroImageWrapper}>
          <img style={sizeAndPosition[i]} src={img.url} alt="" />
        </div>
      </div>;
    });
  }

  function renderHomeImagesFirstVisit() {
    if (!sizeAndPosition.length) {
      generateSizeAndPosition();
    }

    return [...props.homeImages].reverse().map((img, i) => {
      return <motion.div key={`homeImage-${i}`} animate={{ opacity: 1 }} initial={{ opacity: 0 }} transition={{ duration: 0, delay: i === 0 ? 0.1 : i * 0.25 + 0.1 }} className={img.aspectRatio === 'portrait' ? styles.homeImagePortrait : styles.homeImage}>
        <div className={styles.heroImageWrapper}>
          <img style={sizeAndPosition[i]} src={img.url} alt="" />
        </div>
      </motion.div>;
    });
  }

  function renderHome() {
    if (props.firstVisitOnHome) {
      if (props.preloadStatus === 'idle') {
        return renderHomeImagesFirstVisit();
      } else {
        return '';
      }
    } else {
      return renderHomeImages();
    }
  }

  console.log('+++++++++++++++++++++++++++++++++++')
  console.log(props.firstVisitOnHome && props.preloadStatus);
  console.log('+++++++++++++++++++++++++++++++++++')

  return (
    <div className={styles.coverModule} style={props.backdrop ? { backgroundImage: `url(${props.backdrop})` } : {}}>
      {(props.hero || props.home) &&
        <div className={styles.heroContainer}>
          <div className={styles.heroRow}>
            {props.home &&
              <div className={styles.homeTYContainer}>
                <div className={styles.homeTYWrapper}>
                  <motion.div
                    variants={motionVariantsT}
                    initial={props.firstVisitOnHome ? 'initialT' : 'expandedT'}
                    animate={props.firstVisitOnHome && props.preloadStatus === 'loading' ? "initialT" : "expandedT"}
                    className={styles.homeTLetter}>
                    T
                  </motion.div>
                  <motion.div
                    variants={motionVariantsY}
                    initial={props.firstVisitOnHome ? 'initialY' : 'expandedY'}
                    animate={props.firstVisitOnHome && props.preloadStatus === 'loading' ? "initialY" : "expandedY"}
                    className={styles.homeYLetter}>
                    Y
                  </motion.div>
                </div>
              </div>
            }
            {props.home ? (
              renderHome()
            ) : (
              <div className={props.aspectRatio && props.aspectRatio === 'portrait' ? styles.heroImagePortrait : styles.heroImage}>
                <div className={styles.heroImageWrapper}>
                  <img src={props.hero} alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
      }
      {props.title &&
        <div className={styles.heroContainer}>
          <div className={styles.heroRow}>
            <div className={styles.heroTitle}>
              <div className={props.alignBottom ? `${styles.heroTitleWrapper} ${styles.alignBottom}` : styles.heroTitleWrapper}>
                <div className={`${styles.heroTitleText} ${props.textColor === 'black' ? styles.heroTextBlack : ''}`} dangerouslySetInnerHTML={{ __html: props.title }}></div>
                {props.breadcrumb &&
                  <div className={`${styles.heroTitleBreadcrumb} ${props.textColor === 'black' ? styles.heroTextBlack : ''}`}>{props.breadcrumb}</div>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Cover;
