import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from "react-router-dom";
import { motion, usePresence, useAnimation } from "framer-motion"
import { useSelector } from 'react-redux';
import { selectAllProjects } from 'features/api/apiSlice';
import { WIPE_MOTION_VARIANTS, WIPE_LOADING_STATES } from 'utils/js/config';
import { useDispatch } from 'react-redux';
import { setHomeHoverItem, selectPreloadStatus, selectEntryRoute } from 'appSlice';
import Nav from 'features/shared/Nav';

import Header from 'components/common/Header';
import Intro from 'features/shared/Intro';
import WorkGrid from 'features/home/WorkGrid';
import Marquee from 'features/shared/Marquee';
import EndOfPage from 'features/shared/EndOfPage';

function Home(props) {
  const [isPresent, safeToRemove] = usePresence();
  const history = useHistory();
  const projects = useSelector(selectAllProjects());
  const preloadStatus = useSelector(selectPreloadStatus);
  const [isAnimatedIn, setIsAnimatedIn] = useState(false);
  const introRef = useRef();
  const headerRef = useRef();
  const workGridRef = useRef();
  const endOfPageRef = useRef();
  const dispatch = useDispatch();
  const controls = useAnimation();

  const entryRoute = useSelector(selectEntryRoute);
  const firstVisitOnHome = entryRoute.length === 1 && entryRoute[0] === "/";

  const breakpoints = useSelector(state => state.browser.is);
  let breakpoint = Object.keys(breakpoints).find(key => breakpoints[key] === true);

  const animateIn = useCallback(
    async () => {
      await controls.start(WIPE_LOADING_STATES.IN);
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
        setIsAnimatedIn(true);
      }, 0);
    },
    [controls]
  );

  const animateOut = useCallback(
    async () => {
      await controls.start(WIPE_LOADING_STATES.OUT);
      dispatch(setHomeHoverItem(undefined));
      safeToRemove();
    },
    [controls, dispatch, safeToRemove]
  );

  useEffect(() => {
    if (preloadStatus === 'idle') {
      console.log('IDLE TRANSIITON');
      animateIn();
    }
  }, [preloadStatus, controls, animateIn])

  useEffect(() => {
    if (!isPresent) {
      if (history.action === 'PUSH') {
        animateOut();
      } else {
        safeToRemove();
      }
    }
  }, [isPresent, safeToRemove, history.action, dispatch, animateOut]);

  console.log('home header, ', props.slug)
  if (!projects) {
    return false;
  } else {
    return (
      <div style={{ transform: isAnimatedIn ? 'none' : `translateY(${document.documentElement.scrollTop}px)` }}>
        <Nav modules={[
          {
            ref: headerRef,
            navColor: 'white'
          },
          {
            ref: introRef,
            navColor: 'white'
          },
          {
            ref: workGridRef,
            navColor: 'black'
          },
          {
            ref: endOfPageRef,
            navColor: 'black'
          }
        ]} defaultNavColor={'white'} projects={projects} preloadStatus={preloadStatus} firstVisitOnHome={firstVisitOnHome} />
        <motion.div
          style={{ backfaceVisibility: "hidden" }}
          variants={WIPE_MOTION_VARIANTS}
          initial={firstVisitOnHome ? WIPE_LOADING_STATES.IN : WIPE_LOADING_STATES.IDLE}
          animate={controls}>
          <Header
            ref={headerRef}
            modules={[
              {
                ref: introRef,
                navColor: 'white'
              },
              {
                ref: workGridRef,
                navColor: 'black'
              },
              {
                ref: endOfPageRef,
                navColor: 'black'
              }
            ]}
            projects={projects}
            home={true}
            preloadStatus={preloadStatus}
            firstVisitOnHome={firstVisitOnHome} />
          <Intro ref={introRef} home={true} statement={breakpoint === 'small' ? 'Trista Yard is a<br />New York City based designer and director.' : 'Trista Yard is a<br />New York City based<br />designer and director.'} />
          <WorkGrid ref={workGridRef} projects={projects} />
          <Marquee />
          <EndOfPage ref={endOfPageRef} />
        </motion.div>
      </div>
    );
  }
}

//exit={{ opacity: 0 }}

export default Home;
