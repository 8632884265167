import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectHomeHoverItem, selectPreloadStatus } from 'appSlice';
import { useHistory, useLocation } from "react-router-dom";
import { PAGETRANSITION_DURATION_IN_MS } from 'utils/js/config';
import styles from './Nav.module.scss';

import { WIPE_MOTION_VARIANTS, WIPE_LOADING_STATES, NAV_ANIMATION_VARIANTS, NAV_ANIMATION_STATES } from 'utils/js/config';
import { motion, useAnimation } from "framer-motion"


function Nav(props) {
  console.log(props.defaultNavColor);
  const animateOutDelay = 400; // sync with scss
  const history = useHistory();
  const location = useLocation();
  const [activeProject, setActiveProject] = useState(initActiveProject());
  const [heroVisibility, setHeroVisibility] = useState(false);
  const [overlayVisibility, setOverlayVisibility] = useState(false);
  const [expandContact, setExpandContact] = useState(false);
  const [scrolledToEnd, setScrolledToEnd] = useState(false);
  const [topNavColor, setTopNavColor] = useState(props.defaultNavColor ? props.defaultNavColor : 'black');
  const [bottomNavColor, setBottomNavColor] = useState('white');
  const globalHoveredItem = useSelector(selectHomeHoverItem);
  const preloadStatus = useSelector(selectPreloadStatus);
  const breakpoints = useSelector(state => state.browser.is);
  const isTouch = useSelector(state => state.app.isTouch);
  const controls = useAnimation();

  const topNavRef = useRef();
  const bottomNavRef = useRef();

  const isProjectActive = useCallback(
    () => {
      return activeProject;
    },
    [activeProject]
  );

  const animateOut = useCallback(
    async () => {
      await controls.start(WIPE_LOADING_STATES.OUT);
      controls.set(WIPE_LOADING_STATES.IN);
    },
    [controls]
  );

  useEffect(() => {
    if (!isProjectActive()) {
      setTimeout(() => {
        if (!isProjectActive()) setHeroVisibility(false);
      }, animateOutDelay);
    } else {
      setHeroVisibility(true);
    }
  }, [activeProject, isProjectActive]);

  useEffect(() => {
    if (!props.modules || !bottomNavRef || !topNavRef || !bottomNavRef.current || !topNavRef.current) return;

    let bottomNavRect = bottomNavRef.current.getBoundingClientRect();
    let topNavRect = topNavRef.current.getBoundingClientRect();

    function getModule(entry) {
      return props.modules.find((m) => m.ref.current === entry.target);
    }

    let callback = (entries, observer) => {
      entries.forEach((entry, i) => {
        let topNavOffset = 0;
        let bottomNavOffset = 0;
        // second condition makes sure that negative boundingClientRect.y doesn't lead to false positives
        let doesBottomOverlap =
          entry.boundingClientRect.y <= (bottomNavRect.bottom + bottomNavOffset) &&
          (entry.boundingClientRect.height + entry.boundingClientRect.y) >= (bottomNavRect.bottom + bottomNavOffset);

        let doesTopOverlap =
          entry.boundingClientRect.y <= (topNavRect.bottom + topNavOffset) &&
          (entry.boundingClientRect.height + entry.boundingClientRect.y) >= (topNavRect.bottom + topNavOffset);

        const module = getModule(entry);

        if (!module) return;

        if (entry.target.className.includes('workSamplesWrapper')) {
          console.log(`-- ${entry.boundingClientRect.height}`);
        }

        if (doesBottomOverlap) {
          if (bottomNavColor !== module.navColor) {
            setBottomNavColor(module.navColor);
          }

          if (entry.target.className.includes('endOfPage')) {
            console.log(entry.intersectionRatio, entry.intersectionRatio === 1, entry.intersectionRatio < 0.8, scrolledToEnd);
            if (entry.intersectionRatio >= 0.9 && !scrolledToEnd) {
              console.log('scrolledToEnd = true')

              setScrolledToEnd(true);
            }
          }

          if (entry.intersectionRatio < 0.8 && scrolledToEnd) {
            console.log('scrolledToEnd = false')
            setScrolledToEnd(false);
          }
        }

        // if (!doesBottomOverlap && )

        if (doesTopOverlap) {
          if (topNavColor !== module.navColor) {
            setTopNavColor(module.navColor);
          }
        }
      });
    };

    let io = new IntersectionObserver(callback, {
      threshold: buildThresholdList(),
      rootMargin: "0px 0px 0px 0px"
    });

    props.modules.forEach(target => {
      if (target && target.ref.current) {
        io.observe(target.ref.current)
      }
    });

    return () => {
      props.modules.forEach(target => {
        if (target && target.ref.current) {
          io.unobserve(target.ref.current)
        }
      });
    };
  }, [location.pathname, props.modules, bottomNavColor, topNavColor, scrolledToEnd, preloadStatus]);


  function buildThresholdList() {
    let thresholds = [];
    let incrementValue = 0.002;
    let numOfIncrements = 500;

    for (let i = 0; i <= numOfIncrements; i++) {
      thresholds.push(incrementValue * i);
    }

    return thresholds;
  }


  function initActiveProject() {
    if (props.slug) {
      const activeProject = props.projects.find((p, i) => p.slug === props.slug);
      return activeProject.id;
    }

    return false;
  }

  function restoreActiveProject() {
    if (props.slug) {
      const activeProject = props.projects.find((p, i) => p.slug === props.slug);
      setActiveProject(activeProject.id);
    } else {
      setActiveProject(false);
    }
  }

  function getActiveProjectClass(id) {
    if (id === activeProject) {
      return styles.projectActive;
    } else {
      return styles.projectMuted;
    }
  }

  function renderNavItems() {
    return props.projects.map(project => {
      return <li
        key={project.id}
        className={`${isTouch ? styles.projectTouch : styles.project} ${activeProject ? getActiveProjectClass(project.id) : ''}`}
        onMouseEnter={() => !isTouch ? setActiveProject(project.id) : null}
        onClick={() => {
          setTimeout(() => {
            setActiveProject(project.id);
            setHeroVisibility(false);
            setOverlayVisibility(false);
          }, PAGETRANSITION_DURATION_IN_MS);
          animateOut();
          history.push(`/work/${project.slug}`)
        }}>
        {project.title}
      </li>;
    })
  }

  function renderCoverImage() {
    const p = props.projects.find(project => project.id === activeProject);

    if (p) {
      return <img src={p.coverHeroImage.url} alt="" />;
    }

    return false;
  }

  function renderProjectNumber() {
    let count = 0;
    props.projects.find((project, i) => {
      if (project.id === activeProject) {
        count = i;
        return true;
      }

      return false;
    });

    return `/0${count + 1}`;
  }

  function activeProjectAspectRatio() {
    let count = 0;
    props.projects.find((project, i) => {
      if (project.id === activeProject) {
        count = i;
        return true;
      }

      return false;
    });

    let aspectRatio = 'landscape';
    if (
      count === 0 ||
      count === 3 ||
      count === 4 ||
      count === 7 ||
      count === 8) {
      aspectRatio = 'portrait';
    }

    return aspectRatio;
  }

  function getNavAnimation() {
    if (props.firstVisitOnHome) {
      if (props.preloadStatus === 'idle') {
        return NAV_ANIMATION_STATES.SHOW;
      } else {
        return NAV_ANIMATION_STATES.HIDE;
      }
    } else {
      return NAV_ANIMATION_STATES.SHOW;
    }
  }

  console.log(`activeProject ${activeProject}`);

  return (
    <React.Fragment>
      <motion.div
        className={styles.navModule}
        variants={NAV_ANIMATION_VARIANTS}
        initial={props.firstVisitOnHome ? NAV_ANIMATION_STATES.HIDE : NAV_ANIMATION_STATES.SHOW}
        animate={getNavAnimation()}>
        <nav className={styles.nav}>
          <ul className={styles.menu}>
            {overlayVisibility ? (
              <React.Fragment>
                <li className={styles.closeButton} onClick={() => setOverlayVisibility(false)}><span></span>Close</li>
                { breakpoints.small &&
                  <React.Fragment>
                    <li ref={bottomNavRef} style={{ color: '#FFF' }} className={styles.aboutMobile}>
                      <div onClick={() => history.push('/about')}>About</div>
                    </li>
                    <li className={styles.contactMobile}>
                      <div className={styles.contactInfo} onMouseLeave={() => !isTouch ? setExpandContact(false) : null}>
                        <div className={styles.contactInfoItem}>
                          <a href="https://www.linkedin.com/in/trista-yard-1980711a/" target="_blank">LinkedIn</a>
                        </div>
                        <div className={styles.contactInfoItem}>
                          <a href="https://www.instagram.com/trista_y" target="_blank">Instagram</a>
                        </div>
                        <div className={styles.contactInfoItem}>
                          <a href="mailto:info@tristayard.com">info@tristayard.com</a>
                        </div>
                      </div>
                    </li>
                  </React.Fragment>
                }
              </React.Fragment>
            ) : (
              <React.Fragment>
                <li ref={topNavRef} className={styles.logo} style={{ color: globalHoveredItem ? '#FFF' : topNavColor }} onClick={() => location.pathname !== '/' ? history.push('/') : ''}>Trista Yard<br />Designer + Director</li>
                <li className={styles.pathfinder} style={{ color: globalHoveredItem ? '#FFF' : topNavColor }} onClick={() => setOverlayVisibility(true)}>
                  <span style={{ backgroundColor: globalHoveredItem ? '#FFF' : topNavColor }}></span>
                  {breakpoints.small ? 'Menu' : 'Selected Work'}
                </li>
                <li ref={bottomNavRef} style={{ color: globalHoveredItem ? '#FFF' : bottomNavColor }} className={styles.about}>
                  <div onClick={() => history.push('/about')}>About</div>
                  <div className={scrolledToEnd ? styles.show : styles.hide}>© 2020</div>
                  <div className={scrolledToEnd ? styles.show : styles.hide}>Development by <a href="https://xxx.manuelvivoda.com" target="_blank">Manuel Vivoda</a></div>
                </li>
                <li className={styles.contact}>
                  {(expandContact || scrolledToEnd) ? (
                    <div className={styles.contactInfo} onMouseLeave={() => !isTouch ? setExpandContact(false) : null}>
                      <div className={styles.contactInfoItem}>
                        <a style={{ color: globalHoveredItem ? '#FFF' : bottomNavColor }} href="https://www.linkedin.com/in/trista-yard-1980711a/" target="_blank">LinkedIn</a>
                      </div>
                      <div className={styles.contactInfoItem}>
                        <a style={{ color: globalHoveredItem ? '#FFF' : bottomNavColor }} href="https://www.instagram.com/trista_y" target="_blank">Instagram</a>
                      </div>
                      <div className={styles.contactInfoItem}>
                        <a style={{ color: globalHoveredItem ? '#FFF' : bottomNavColor }} href="mailto:info@tristayard.com">info@tristayard.com</a>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.contactButton} style={{ color: globalHoveredItem ? '#FFF' : bottomNavColor }} onMouseEnter={() => setExpandContact(true)}>Contact</div>
                  )}
                </li>
              </React.Fragment>
            )}
          </ul>
        </nav>
      </motion.div>
      {overlayVisibility &&
        <motion.div
          className={styles.workOverviewOverlay}
          variants={WIPE_MOTION_VARIANTS}
          initial={WIPE_LOADING_STATES.IN}
          animate={controls}>
          <div className={styles.overlayContentContainer}>
            <div className={styles.overlayContentRow}>
              <div className={activeProjectAspectRatio() === 'portrait' ? styles.projectHeroPortrait : styles.projectHero}>
                {heroVisibility &&
                  <div className={activeProject ? styles.projectHeroWrapper : styles.projectHeroWrapperHidden}>
                    {renderCoverImage()}
                  </div>
                }
              </div>
              <div className={styles.projectListCol}>
                <div className={styles.projectListWrapper}>
                  <ul className={isTouch ? styles.projectListTouch : styles.projectList} onMouseLeave={() => !isTouch ? restoreActiveProject() : null}>
                    {renderNavItems()}
                  </ul>
                  {heroVisibility &&
                    <div className={activeProject ? styles.projectNumber : styles.projectNumberHidden}>{renderProjectNumber()}</div>
                  }
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      }
    </React.Fragment>
  );
}

export default Nav;
