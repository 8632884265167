import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { selectAboutData, selectAllProjects } from 'features/api/apiSlice';
import { selectPreloadStatus, selectEntryRoute } from 'appSlice';
import { motion, usePresence, useAnimation } from "framer-motion"
import { WIPE_MOTION_VARIANTS, WIPE_LOADING_STATES } from 'utils/js/config';

import Nav from 'features/shared/Nav';
import Header from 'components/common/Header';
import EndOfPage from 'features/shared/EndOfPage';
import Marquee from 'features/shared/Marquee';
import Awards from 'features/about/Awards';
import Logos from 'features/about/Logos';

function About(props) {
  const [isPresent, safeToRemove] = usePresence();
  const projects = useSelector(selectAllProjects());
  const aboutData = useSelector(selectAboutData());
  const awardsRef = useRef();
  const logosRef = useRef();
  const marqueeRef = useRef();
  const endOfPageRef = useRef();
  const headerRef = useRef();
  const history = useHistory();
  const controls = useAnimation();
  const [isAnimatedIn, setIsAnimatedIn] = useState(false);
  const entryRoute = useSelector(selectEntryRoute);
  const preloadStatus = useSelector(selectPreloadStatus);

  const breakpoints = useSelector(state => state.browser.is);
  let breakpoint = Object.keys(breakpoints).find(key => breakpoints[key] === true);

  const animateIn = useCallback(
    async () => {
      await controls.start(WIPE_LOADING_STATES.IN);
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
        setIsAnimatedIn(true);
      }, 0);
    },
    [controls]
  );

  const animateOut = useCallback(
    async () => {
      await controls.start(WIPE_LOADING_STATES.OUT);
      safeToRemove();
    },
    [controls, safeToRemove]
  );

  useEffect(() => {
    if (preloadStatus === 'idle' && (history.action === 'PUSH' || entryRoute.length === 1)) {
      console.log('IDLE TRANSIITON');
      animateIn();
    }
  }, [preloadStatus, controls, animateIn, history.action, entryRoute])

  useEffect(() => {
    if (!isPresent) {
      if (history.action === 'PUSH') {
        animateOut();
      } else {
        safeToRemove();
      }
    }
  }, [isPresent, safeToRemove, animateOut, history.action]);

  if (!aboutData) return false;

  return (
    <div style={{ transform: isAnimatedIn ? 'none' : `translateY(${document.documentElement.scrollTop}px)` }}>
      <Nav modules={[
        {
          ref: headerRef,
          navColor: 'white'
        },
        {
          ref: logosRef,
          navColor: 'black'
        },
        {
          ref: awardsRef,
          navColor: 'black'
        },
        {
          ref: marqueeRef,
          navColor: 'black'
        },
        {
          ref: endOfPageRef,
          navColor: 'black'
        }
      ]} projects={projects} />
      <motion.div
        variants={WIPE_MOTION_VARIANTS}
        initial={history.action !== 'PUSH' && entryRoute.length > 1 ? WIPE_LOADING_STATES.IN : WIPE_LOADING_STATES.IDLE}
        animate={controls}>
        <Header
          ref={headerRef}
          modules={[
            {
              ref: logosRef,
              navColor: 'black'
            },
            {
              ref: awardsRef,
              navColor: 'black'
            },
            {
              ref: marqueeRef,
              navColor: 'black'
            },
            {
              ref: endOfPageRef,
              navColor: 'black'
            }
          ]}
          projects={projects}
          backdrop={aboutData.heroImage.url}
          title={breakpoint === 'small' ? 'Trista Yard is a<br />New York City<br />based designer<br />and director.' : 'Trista Yard is a<br />New York City based<br />designer and director.'} />
        <Logos ref={logosRef} />
        <Awards ref={awardsRef} awards={aboutData.awardsRecognitions} />
        <Marquee ref={marqueeRef} />
        <EndOfPage ref={endOfPageRef} />
      </motion.div>
    </div>
  );
}

export default About;

