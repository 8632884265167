import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectHomeHoverItem } from 'appSlice';
import { BrowserRouter as Router } from 'react-router-dom'
import { fetchContent } from 'features/api/apiSlice';
import { Routes } from './routes/configure-routes';
import GridOverlay from './components/helpers/GridOverlay';
import Preloader from './features/shared/Preloader';
import { motion } from "framer-motion"
import './App.css';

function App() {
  const dispatch = useDispatch();
  const hoveredItem = useSelector(selectHomeHoverItem);
  const isTouch = useSelector(state => state.app.isTouch);

  const motionVariants = {
    "initial": {
      backgroundColor: "#fff",
    },
    "fadeIn": {
      backgroundColor: "#000",
      transition: {
        duration: 0,
        ease: 'easeOut'
      }
    },
    "fadeOut": {
      backgroundColor: "#FFF",
      transition: {
        duration: 0,
        ease: 'easeOut'
      }
    }
  };

  useEffect(() => {
    dispatch(fetchContent());
  }, [dispatch]);

  useEffect(() => {
    if (isTouch) {
      let root = document.getElementsByTagName('html')[0];
      root.className += 'mobile';
    }
  }, [isTouch])

  //<GridOverlay />
  return (
    <div
      style={{ backgroundColor: hoveredItem ? "#000" : "#FFF" }}
      className="App">
      <Router>
        <Preloader />
        <Routes />
      </Router>
    </div>
  );
}

export default App;
