import React, { useState, useRef, useEffect } from 'react';
import { motion } from "framer-motion"
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { selectHomeHoverItem } from 'appSlice';
import styles from './WorkGrid.module.scss';
import { useInViewport } from 'react-in-viewport';
import { setHomeHoverItem } from 'appSlice';

function WorkGridItem(props) {
  const history = useHistory();
  const [inViewport, setInViewport] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(false)
  const globalHoveredItem = useSelector(selectHomeHoverItem);
  const isTouch = useSelector(state => state.app.isTouch);

  const dispatch = useDispatch();
  const myRef = useRef();
  useInViewport(
    myRef,
    { threshold: 0.2 },
    { disconnectOnLeave: true },
    {
      onEnterViewport: onEnterViewport,
      onLeaveViewport: onLeaveViewport
    }
  );

  function onEnterViewport() {
    console.log(`onEnterViewport: ${props.project.title}`);
    setInViewport(true);
  }

  function onLeaveViewport() {
    return;
  }

  function navigateToDetailPage(slug) {
    history.push(`/work/${slug}`);
  }

  function onHover(slug) {
    dispatch(setHomeHoverItem(slug));
    setHoveredItem(slug);
    console.log('onHover')
  }

  function onHoverOut() {
    dispatch(setHomeHoverItem(undefined));
    setHoveredItem(false);
    console.log('onHoverOut')
  }

  if (props.projectNumber === '1') {
    console.log(props.project.title);
  }

  const motionVariants = {
    initial: { opacity: 0, y: 50 },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        delay: 0,
        ease: 'easeOut'
      }
    }
  };

  console.log(`+++++ ${hoveredItem}`)

  return (
    <motion.article
      ref={myRef}
      variants={motionVariants}
      initial="initial"
      animate={inViewport ? "animate" : "initial"}
      className={props.cn}
      onClick={() => navigateToDetailPage(props.project.slug)}>
      <div
        onMouseOver={() => !isTouch ? onHover(props.project.slug) : null}
        onMouseOut={() => !isTouch ? onHoverOut() : null}>
        <div className={(globalHoveredItem && hoveredItem !== globalHoveredItem) ? styles.projectThumbnailMuted : styles.projectThumbnail}>
          {props.project.homeGridHero && props.project.homeGridHero.mimeType.includes('video') ? (
            <video autoPlay={true} loop={true} preload="auto" muted={true} playsInline={true}>
              <source src={props.project.homeGridHero.url} type={props.project.homeGridHero.mimeType} />
            </video>
          ) : (
            <img src={props.cn === styles.workGridItem ? props.project.coverHeroImage.url : props.project.coverHeroImage.url} alt="" />
          )}
        </div>
        <div className={`${styles.workGridItemFooter} ${globalHoveredItem ? styles.workGridItemFooterInvert : ''} ${(globalHoveredItem && hoveredItem !== globalHoveredItem) ? styles.workGridItemFooterHide : ''}`}>
          <div className={props.cn === styles.workGridItem ? styles.workGridItemDotExpanded : styles.workGridItemDot}><span></span></div>
          <div className={props.cn === styles.workGridItem ? styles.workGridItemTitleAndRoleExpanded : styles.workGridItemTitleAndRole}>
            {props.project.title}<br />{props.project.responsibility}
          </div>
          <div className={props.cn === styles.workGridItem ? styles.workGridItemPageExpanded : styles.workGridItemPage}>/0{props.projectNumber}</div>
        </div>

        {hoveredItem &&
          <div data-number={props.projectNumber % 2 === 0 ? 'odd' : 'even'} className={`${props.cn === styles.workGridItemPortrait ? styles.workGridItemHoverOverlayModulePortrait : styles.workGridItemHoverOverlayModule}`}>
            <div className={styles.workGridItemHoverOverlayContainer}>
              <div className={styles.workGridItemHoverOverlayRow}>
                <div className={styles.workGridItemHoverOverlayWrapper}>
                  <div className={styles.workGridItemHoverOverlayText}>
                    {props.project.title}
                  </div>
                  <div className={styles.workGridItemHoverOverlayBreadcrumb}>
                    /0{props.projectNumber}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </motion.article>
  );
}

export default WorkGridItem;
