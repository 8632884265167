import React from 'react';
import { useSelector } from 'react-redux';
import styles from './EndOfPage.module.scss';

const EndOfPage = React.forwardRef((props, ref) => {
  const breakpoints = useSelector(state => state.browser.is);
  let breakpoint = Object.keys(breakpoints).find(key => breakpoints[key] === true);

  return (
    <div ref={ref} className={breakpoint === 'small' ? styles.endOfPageModuleMobile : styles.endOfPageModule}>
      {breakpoint === 'small' &&
        <div className={styles.mobileFooterContainer}>
          <div className={styles.mobileFooterRow}>
            <p className={styles.mobileFooterCopyright}>
              ©2020
            </p>
            <p className={styles.mobileFooterDevCredit}>
              Development by <a href="https://xxx.manuelvivoda.com/" target="_blank">Manuel Vivoda</a>
            </p>
          </div>
        </div>
      }
    </div>
  );
})

export default EndOfPage;
