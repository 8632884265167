import React from 'react';
import styles from './Marquee.module.scss';
import { useSelector } from 'react-redux';
import { selectHomeHoverItem } from 'appSlice';

const Marquee = React.forwardRef((props, ref) => {
  const globalHoveredItem = useSelector(selectHomeHoverItem);

  return (
    <div ref={ref} className={`${styles.marqueeModule} ${globalHoveredItem ? styles.marqueeModuleInvert : ''}`}>
      <div className={styles.marqueeContainer}>
        <div className={styles.marqueeWrapper}>
          <div className={styles.marqueeInner}>
            <p>TY for stopping by. &nbsp; <a href="mailto:info@tristayard.com">Say hello</a>&nbsp;to ask about upcoming availability. <span></span></p>
            <p>TY for stopping by. &nbsp; <a href="mailto:info@tristayard.com">Say hello</a>&nbsp;to ask about upcoming availability. <span></span></p>
            <p>TY for stopping by. &nbsp; <a href="mailto:info@tristayard.com">Say hello</a>&nbsp;to ask about upcoming availability. <span></span></p>
            <p>TY for stopping by. &nbsp; <a href="mailto:info@tristayard.com">Say hello</a>&nbsp;to ask about upcoming availability. <span></span></p>
          </div>
        </div>
      </div>
    </div>
  );
})

export default Marquee;
