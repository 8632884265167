import React, { useRef, useState, useEffect } from 'react';
import { motion } from "framer-motion"
import { useInViewport } from 'react-in-viewport';

function WorkSampleContentItem(props) {
  const [inViewport, setInViewport] = useState(false);
  const [threshold, setThreshold] = useState(0.2);
  const elRef = useRef();
  useInViewport(
    elRef,
    { threshold: threshold },
    { disconnectOnLeave: true },
    {
      onEnterViewport: onEnterViewport,
      onLeaveViewport: onLeaveViewport
    }
  );

  function recalculateThreshold() {
    if (elRef.current) {
      console.log('----', elRef.current.offsetHeight);
      let t = 100 / elRef.current.offsetHeight > 1 ? 1 : 100 / elRef.current.offsetHeight; // ensure that threshold stays below 1
      setThreshold(t);
    }
  }

  useEffect(() => {
    const resizeListener = () => {
      recalculateThreshold();
    };

    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  function onEnterViewport() {
    setInViewport(true);
  }

  function onLeaveViewport() {
    return;
  }

  const motionVariants = {
    initial: { opacity: 0, y: 50 },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        delay: 0,
        ease: 'easeOut'
      }
    }
  };

  return (
    (props.sample.mimeType.includes('video')) ? (
      <motion.div
        className={props.className}
        ref={elRef}
        variants={motionVariants}
        initial="initial"
        animate={inViewport ? "animate" : "initial"}>
        <video autoPlay={true} loop={true} preload="auto" playsInline={true} muted={true} onPlay={() => recalculateThreshold()}>
          <source src={props.sample.url} type={props.sample.mimeType} />
        </video>
      </motion.div>
    ) : (
      <motion.div
        className={props.className}
        ref={elRef}
        variants={motionVariants}
        initial="initial"
        animate={inViewport ? "animate" : "initial"}>
        <img onLoad={() => recalculateThreshold()} src={props.sample.url} alt="" />
      </motion.div>
    )
  );
}

export default WorkSampleContentItem;
