import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence } from "framer-motion";
import Home from 'pages/home/Home';
import About from 'pages/about/About';
import WorkDetail from 'pages/detail/WorkDetail';

import { setEntryRoute, selectEntryRoute } from 'appSlice';



export function Routes() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const entryRoute = useSelector(selectEntryRoute);

  if (entryRoute.length < 2 && (entryRoute.length === 0 || entryRoute[entryRoute.length - 1] !== history.location.pathname)) {
    dispatch(setEntryRoute(history.location.pathname));
  }

  return (
    <AnimatePresence exitBeforeEnter={history.action === 'PUSH' ? true : false}>
      <Switch location={location} key={location.pathname}>
        <Route
          exact
          path="/"
          render={(props) => <Home {...props} name="Home" />}
        />
        <Route
          exact
          path="/about"
          render={(props) => <About {...props} name="About" />}
        />
        <Route
          exact
          path="/work/:slug"
          render={(props) => <WorkDetail {...props} name="WorkDetail" />}
        />
        <Redirect to="/" />
      </Switch>
    </AnimatePresence>
  );
}
