import React from 'react';
import WorkSampleContentItem from './WorkSampleContentItem';
import styles from './WorkSample.module.scss';

function WorkSample(props) {
  function sampleClassName() {
    let c = styles.workSampleFull;

    if (props.format === 'medium') {
      c = styles.workSampleMedium;
    } else if (props.format === 'x_large') {
      c = styles.workSampleXLarge;
    } else if (props.format === 'large') {
      c = styles.workSampleLarge;
    } else if (props.format === 'image_half') {
      c = styles.workSampleImageHalf;
    } else if (props.format === 'image_variety') {
      c = styles.workSampleImageVariety;
    }

    return c;
  }

  function getImage() {
    return props.sample.map((sample) => {
      return <WorkSampleContentItem key={sample.id} sample={sample} className={sampleClassName()} />;
    })
  }

  function renderImageVariety() {
    let maxProjectsPerRow = 2;
    // array of N elements, where N is the number of rows needed
    const rows = [...Array(Math.ceil(props.sample.length / maxProjectsPerRow))];
    // chunk the products into the array of rows
    const projectRows = rows.map((row, i) => props.sample.slice(i * maxProjectsPerRow, i * maxProjectsPerRow + maxProjectsPerRow));
    // map the rows as div.row
    let projectNumber = 0;
    return projectRows.map(
      (row, i) => {
        return <div className={styles.workSampleRow} key={i}>
          {row.map((sample, j) => {

            let cn = styles.workSampleImageVarietyPortrait;
            if (i % 2 === 0) {
              if (j === 1) {
                cn = styles.workSampleImageVariety;
              }
            } else {
              if (j === 0) {
                cn = styles.workSampleImageVariety;
              }
            }

            return <WorkSampleContentItem key={sample.id} sample={sample} className={cn} />;
          })
          }
        </div>
      }
    );
  }

  function getModuleClassName() {
    if (props.index === 0 && props.format !== 'full_bleed') {
      return styles.workSampleModulePadded;
    } else if (props.format === 'full_bleed') {
      return styles.workSampleModuleFullBleed;
    } else {
      return styles.workSampleModule;
    }
  }

  console.log(props.bgColor);

  return (
    <div className={getModuleClassName()} style={{ backgroundColor: props.bgColor }}>
      {props.format === 'full_bleed' ? (
        getImage()
      ) : (
        <div className={styles.workSampleContainer}>
          {props.format === 'image_variety' ? (
            renderImageVariety()
          ) : (
            <div className={styles.workSampleRow}>
              {getImage()}
            </div>
          )}
        </div>
      )
      }
    </div>
  );
}

export default WorkSample;
