import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion"
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import WorkGridItem from "./WorkGridItem";
import styles from './WorkGrid.module.scss';

const WorkGrid = React.forwardRef((props, ref) => {
  const history = useHistory();
  //const breakpoints = useSelector(state => state.browser.is);
  //let breakpoint = Object.keys(breakpoints).find(key => breakpoints[key] === true);

  function navigateToDetailPage(slug) {
    history.push(`/work/${slug}`);
  }

  function renderProjects() {
    let maxProjectsPerRow = 2;
    // array of N elements, where N is the number of rows needed
    const rows = [...Array(Math.ceil(props.projects.length / maxProjectsPerRow))];
    // chunk the products into the array of rows
    const projectRows = rows.map((row, i) => props.projects.slice(i * maxProjectsPerRow, i * maxProjectsPerRow + maxProjectsPerRow));
    // map the rows as div.row
    let projectNumber = 0;
    return projectRows.map(
      (row, i) => {
        return <div className={styles.workGridRow} key={i}>
          {row.map((project, j) => {

            let cn = styles.workGridItemPortrait;
            if (i % 2 === 0) {
              if (j === 1) {
                cn = styles.workGridItem;
              }
            } else {
              if (j === 0) {
                cn = styles.workGridItem;
              }
            }

            projectNumber++;

            return <WorkGridItem key={project.id} project={project} cn={cn} projectNumber={projectNumber} />;
          })
          }
        </div>
      }
    );
  }

  return (
    <div ref={ref} className={styles.workGridModule}>
      <div className={styles.workGridContainer}>
        {renderProjects()}
      </div>
    </div>
  );
});

export default WorkGrid;
