import React from 'react';
import styles from './Awards.module.scss';

const Awards = React.forwardRef((props, ref) => {
  function renderAwardsList() {
    return props.awards.map((award) => {
      return <div key={award.id} className={styles.award}>
        <span className={styles.awardHorizontalLine}></span>
        <div className={styles.awardDetails}>
          <div className={styles.awardBullet}>
            <span></span>
          </div>
          <div className={styles.awardName}>
            {award.name}
          </div>
          <div className={styles.awardCategory}>
            {award.category}
          </div>
        </div>
      </div>;
    })
  }

  return (
    <div ref={ref} className={styles.awardsModule}>
      <div className={styles.awardsContainer}>
        <div className={styles.awardsRow}>
          <div className={styles.awardsHeadline}>
            <h2>Awards & Recognition</h2>
          </div>
          <div className={styles.awardsList}>
            {renderAwardsList()}
          </div>
        </div>
      </div>
    </div>
  );
})

export default Awards;
