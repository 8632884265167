import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { motion, useAnimation } from "framer-motion"
import { useLocation, useHistory } from 'react-router-dom';
import { selectAllProjects } from 'features/api/apiSlice';
import { setPreloadStatus, selectEntryRoute } from 'appSlice';
import { PAGETRANSITION_DURATION_IN_MS, WIPE_DURATION_IN_SEC, WIPE_EASING } from 'utils/js/config';
import { Promise } from "bluebird";

import styles from './Preloader.module.scss';

Promise.config({
  cancellation: true
});

const LOADING_STATES = {
  IDLE: "idle",
  LOADING: "loading",
  COMPLETE: "complete"
};


function Preloader(props) {
  const firstRender = useRef(true);
  const timerRef = useRef();
  const location = useLocation();
  const controls = useAnimation();
  const history = useHistory();
  const dispatch = useDispatch();
  const projects = useSelector(selectAllProjects());
  const [preloadProgress, setPreloadProgress] = useState([]);
  const entryRoute = useSelector(selectEntryRoute);
  const isTouch = useSelector(state => state.app.isTouch);

  const firstVisitOnHome = entryRoute.length === 1 && entryRoute[0] === "/";

  const motionVariants = {
    [LOADING_STATES.IDLE]: {
      y: "150vh",
      display: "none",
      transition: {
        duration: 0,
        delay: 0
      }
    },
    [LOADING_STATES.LOADING]: {
      display: "block",
      y: "-25vh",
      transition: {
        duration: WIPE_DURATION_IN_SEC,
        delay: 0,
        ease: WIPE_EASING
      }
    },
    [LOADING_STATES.COMPLETE]: {
      display: "block",
      y: "-150vh",
      transition: {
        duration: WIPE_DURATION_IN_SEC,
        delay: 0,
        ease: WIPE_EASING
      }
    }
  };

  const animateOutSequence = useCallback(
    async () => {
      if (firstVisitOnHome) {
        document.documentElement.classList.remove('lockscroll');
      }
      dispatch(setPreloadStatus('idle'));
      await controls.start(LOADING_STATES.COMPLETE);
      controls.start(LOADING_STATES.IDLE)
    },
    [controls, entryRoute]
  );

  useEffect(() => {
    if (firstVisitOnHome) {
      document.documentElement.classList.add('lockscroll');
    }
  }, [firstVisitOnHome]);

  useEffect(() => {
    let preloader;

    function getAssetsBasedOnLocation() {
      let assetsArr = [];

      if (location.pathname === '/') {
        projects.forEach((p) => {
          const { coverHeroImage } = p;
          assetsArr.push(coverHeroImage);
        });
      } else if (location.pathname.includes('work')) {
        let project = projects.find((p) => {
          return location.pathname.includes(p.slug)
        });

        if (project.homeGridHero) {
          assetsArr.push(project.homeGridHero);
        }

        if (project.coverHeroImage) {
          assetsArr.push(project.coverHeroImage);
        }

        for (let i = 0; i < 4; i++) {
          if (project.workSamples[i] && project.workSamples[i].sampleAsset) {
            project.workSamples[i].sampleAsset.forEach((a) => {
              if (a.mimeType.includes('image')) {
                assetsArr.push(a);
              }
            })
          }
        }
      }

      return assetsArr;
    }

    function loadImage(url, assetIndex) {
      return new Promise((resolve, reject, onCancel) => {
        let cancelled = false;
        let request;

        if (url) {
          request = new XMLHttpRequest();
          request.onprogress = function (e) {
            let progress = (e.loaded / e.total * 100);
            setPreloadProgress(arr => {
              arr[assetIndex] = progress;
              return [...arr];
            });
          };
          request.onload = function (e) {
            if (!cancelled) {
              resolve(`success: ${url}`)
            }
          };

          request.open("GET", url, true);
          request.responseType = 'arraybuffer';
          request.send();
        }

        onCancel(() => {
          cancelled = true;
          request.abort();
          reject({ reason: 'cancelled' });
        });
      });
    }

    // second condition covers page load (first visit)
    if (history.action === 'PUSH' || (firstRender.current && projects)) {
      console.log('history.action === PUSH', firstRender)

      setPreloadProgress(arr => []);

      // PRELOADER SEQUENCE
      // 1. START WIPE UP
      // 2. START PRELOADING ASSETS ON WIPE FINISH (setTimeout set at PAGETRANSITION_DURATION_IN_MS)
      // 3. WAIT 400ms AFTER PRELOADING IS COMPLETE TO ENSURE BAR EXTENDS 100%

      // START WIPE
      controls.start(LOADING_STATES.LOADING);
      dispatch(setPreloadStatus('loading'));

      if (timerRef.current) clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        if (projects) {
          const assets = getAssetsBasedOnLocation();
          preloader = Promise.all(assets.map((p, i) => {
            console.log(p);
            if (p.url) {
              setPreloadProgress(arr => [...arr, 0]);
              return new loadImage(p.url, i);
            }
          })).then((values) => {
            // window.scrollTo(0, 0);
            if (isTouch) document.body.scrollTop = 0;

            // allows preloader bar to extend to 100% before transitioning out
            setTimeout(() => {
              animateOutSequence();
            }, 400)

          });
        }
      }, firstRender.current ? 0 : PAGETRANSITION_DURATION_IN_MS);

      firstRender.current = false;
    }

    return () => {
      if (preloader) {
        preloader.cancel();
        dispatch(setPreloadStatus('idle'));
      }
    };
  }, [
    location.pathname,
    history.action,
    projects,
    controls,
    animateOutSequence,
    dispatch,
    location
  ]);

  function getPreloaderWidth() {
    let totalProgress = 0;
    if (preloadProgress.length) {
      preloadProgress.forEach((p) => {
        totalProgress += p;
      });
      return totalProgress / preloadProgress.length / 100;
    }

    return 0;
  }

  return (
    <motion.div
      className={firstVisitOnHome ? styles.preloadModuleFirstVisitOnHome : styles.preloaderModule}
      variants={motionVariants}
      initial={LOADING_STATES.LOADING}
      animate={controls}>
      <div className={styles.preloaderContainer}>
        <div className={styles.preloaderProgressBarContainer}>
          <div className={styles.preloaderProgressBar} style={{ transform: `scaleX(${getPreloaderWidth()}) translateZ(0)` }}></div>
        </div>
        {!firstVisitOnHome &&
          <div className={styles.preloaderGraphic}>
            <svg width="100%" height="100%" viewBox="0 0 73 59" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M59.2178 17.1278C56.62 12.6282 52.7463 8.99998 48.0866 6.70181C43.4268 4.40364 38.1903 3.53876 33.039 4.2165C27.8878 4.89425 23.0531 7.08419 19.1464 10.5094C15.2397 13.9347 12.4363 18.4414 11.0908 23.4598C9.74522 28.4782 9.9179 33.7829 11.587 38.7031C13.2561 43.6234 16.3466 47.9383 20.4678 51.1022C24.589 54.2661 29.5558 56.137 34.7402 56.4783C39.9247 56.8196 45.0939 55.616 49.5943 53.0196C52.5833 51.2953 55.2033 48.9989 57.3047 46.2618C59.406 43.5246 60.9475 40.4003 61.8412 37.0673C62.7349 33.7342 62.9632 30.2578 62.513 26.8366C62.0629 23.4153 60.9432 20.1162 59.2178 17.1278ZM17.4577 41.238C15.289 37.4818 14.2828 33.1669 14.5663 28.8388C14.8498 24.5108 16.4102 20.364 19.0502 16.9227C21.6903 13.4814 25.2914 10.9002 29.3983 9.50542C33.5052 8.11064 37.9335 7.96491 42.1233 9.08667C46.313 10.2084 50.0761 12.5473 52.9366 15.8075C55.7972 19.0678 57.6269 23.1031 58.1943 27.4031C58.7617 31.7031 58.0413 36.0749 56.1243 39.9655C54.2073 43.8562 51.1797 47.0911 47.4244 49.2612C42.3856 52.1599 36.403 52.9421 30.7883 51.4365C25.1736 49.9309 20.385 46.2602 17.4726 41.2294L17.4577 41.238Z" fill="white" />
              <path d="M44.6453 41.446C41.4809 43.273 37.7203 43.7681 34.1908 42.8223C30.6614 41.8766 27.6522 39.5676 25.8252 36.4032C23.9982 33.2387 23.5031 29.4782 24.4488 25.9487C25.3946 22.4193 27.7036 19.4101 30.868 17.5831L28.7153 13.8545C24.562 16.2524 21.5314 20.202 20.2902 24.8344C19.0489 29.4668 19.6987 34.4026 22.0966 38.5559C24.4945 42.7092 28.4441 45.7398 33.0765 46.981C37.7089 48.2223 42.6447 47.5725 46.798 45.1746L44.6453 41.446Z" fill="white" />
              <path d="M50.4492 30.8277L48.2793 27.0693L42.1271 30.6213L44.2971 34.3797L50.4492 30.8277Z" fill="white" />
              <path d="M45.1396 21.6285L42.9697 17.8701L36.8176 21.4221L38.9875 25.1805L45.1396 21.6285Z" fill="white" />
            </svg>
          </div>
        }
      </div>
    </motion.div>
  );
}

export default Preloader;
