import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { current } from 'immer';
import axios from 'axios';
import { request } from 'graphql-request';

// https://immerjs.github.io/immer/docs/update-patterns

const REDUCER_NAME = 'counter';
const API_ENDPOINT = 'https://api-us-east-1.graphcms.com/v2/ckjky8dar454m01z98her78es/master';

export const fetchContent = createAsyncThunk(
  `${REDUCER_NAME}/fetchContent`,
  async (_, thunkAPI) => {
    // const response = await axios.get('https://5ff3bd2c16cf4f0017c1f60b.mockapi.io/api/v1/work');
    const { pages } = await request(
      API_ENDPOINT,
      `
        {
          pages {
            id,
            title,
            posts {
              id,
              title
            }
          }
        }
      `
    );

    return pages;
  }
)

export const counterSlice = createSlice({
  name: REDUCER_NAME,
  initialState: {
    value: 0,
    data: undefined,
  },
  reducers: {
    increment: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: state => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchContent.fulfilled]: (state, action) => {
      // Add user to the state array
      console.log(current(state));
      console.log('------');
      console.log(action.payload);
      state.data = action.payload;
    }
  }
});

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = amount => async dispatch => {
  console.log('incrementAsync 1');
  await new Promise(resolve => {
    setTimeout(() => {
      resolve('done');
    }, 2000);
  });

  console.log('incrementAsync 2');
  dispatch(incrementByAmount(amount));
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCount = state => state[REDUCER_NAME].value;
export const selectData = state => state[REDUCER_NAME].data;

// Extract the action creators object and the reducer
const { actions, reducer } = counterSlice;
// Extract and export each action creator by name
export const { increment, decrement, incrementByAmount } = actions
// Export the reducer, either as a default or named export
export default reducer
