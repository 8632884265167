import React from 'react';
import styles from './Paginator.module.scss';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

const Paginator = React.forwardRef((props, ref) => {
  const history = useHistory();
  const breakpoints = useSelector(state => state.browser.is);

  function processPagination() {
    let currentProject;
    props.projects.find((p, i) => {
      if (p.slug === props.slug) {
        currentProject = {
          data: p,
          index: i
        }

        return true;
      }

      return false;
    });

    const prevIndex = currentProject.index - 1 < 0 ? props.projects.length - 1 : currentProject.index - 1;
    console.log(`prevIndex: ${prevIndex}`);
    const nextIndex = (currentProject.index + 1) % props.projects.length;

    return {
      prev: {
        projectName: props.projects[prevIndex].title,
        projectSlug: props.projects[prevIndex].slug
      },
      next: {
        projectName: props.projects[nextIndex].title,
        projectSlug: props.projects[nextIndex].slug
      }
    }
  }

  const paginationData = processPagination();

  return (
    <div ref={ref} className={styles.paginatorModule}>
      <div className={styles.paginatorContainer}>
        <div className={styles.paginatorRow}>
          <div className={styles.paginatorPrev}>
            <div className={styles.paginatorHitArea} onClick={() => history.push(`/work/${paginationData.prev.projectSlug}`)}>
              <span>Previous</span>
              <p className={breakpoints.small ? '' : styles.paginatorTitleHover}>{paginationData.prev.projectName}</p>
            </div>
          </div>
          <div className={styles.paginatorNext}>
            <div className={styles.paginatorHitArea} onClick={() => history.push(`/work/${paginationData.next.projectSlug}`)}>
              <span>Next</span>
              <p className={breakpoints.small ? '' : styles.paginatorTitleHover}>{paginationData.next.projectName}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
})

export default Paginator;
