import { configureStore } from '@reduxjs/toolkit';
import counterReducer from 'features/counter/counterSlice';
import apiReducer from 'features/api/apiSlice';
import appReducer from 'appSlice';
import {
  responsiveStoreEnhancer,
  createResponsiveStateReducer
} from 'redux-responsive'

export default configureStore({
  reducer: {
    counter: counterReducer,
    api: apiReducer,
    app: appReducer,
    browser: createResponsiveStateReducer({
      small: 767,
      medium: 1023,
    }),
  },
  enhancers: [responsiveStoreEnhancer],
});
