import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './GridOverlay.module.scss';

function GridOverlay() {
  const breakpoints = useSelector(state => state.browser.is)
  console.log(`Breakpoint: ${Object.keys(breakpoints).find(key => breakpoints[key] === true)}`);

  const [gridVisibility, setGridVisibility] = useState(true);
  useEffect(() => {
    window.addEventListener("keyup", function (event) {
      // Number 13 is the "Enter" key on the keyboard
      if (event.keyCode === 71) {
        // Cancel the default action, if needed
        setGridVisibility(!gridVisibility);
      }
    });
  }, [gridVisibility]);

  function createCols() {
    let breakpoint = Object.keys(breakpoints).find(key => breakpoints[key] === true);

    if (breakpoint === 'small') {
      console.log('hiya')
      return [...Array(6)].map((x, i) =>
        <div key={i} className={`${styles.col} col`}>
          <div className={styles.colContent}>
          </div>
        </div>
      );
    } else if (breakpoint === 'medium') {
      return [...Array(10)].map((x, i) =>
        <div key={i} className={`${styles.col} col`}>
          <div className={styles.colContent}>
          </div>
        </div>
      );
    } else {
      return [...Array(12)].map((x, i) =>
        <div key={i} className={`${styles.col} col`}>
          <div className={styles.colContent}>
          </div>
        </div>
      );
    }

  }

  return (
    <div className={styles.wrapper}>
      {gridVisibility &&
        <div className={styles.container}>
          <div className={styles.row}>
            {createCols()}
          </div>
        </div>
      }
    </div>
  );
}

export default GridOverlay;
