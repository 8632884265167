import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { motion, usePresence, useAnimation } from "framer-motion"
import { WIPE_MOTION_VARIANTS, WIPE_LOADING_STATES } from 'utils/js/config';

import Nav from 'features/shared/Nav';
import Intro from 'features/shared/Intro';
import Header from 'components/common/Header';
import WorkSample from 'features/work/WorkSample';
import Paginator from 'features/work/Paginator';
import EndOfPage from 'features/shared/EndOfPage';

import { useSelector } from 'react-redux';
import { selectProjectBySlug, selectAllProjects } from 'features/api/apiSlice';
import { selectPreloadStatus, selectEntryRoute } from 'appSlice';

import styles from './WorkDetail.module.scss';

function WorkDetail(props) {
  const { slug } = useParams();
  const introRef = useRef(null);
  const workSamplesRef = useRef();
  const paginatorRef = useRef();
  const endOfPageRef = useRef();
  const headerRef = useRef();
  const [isPresent, safeToRemove] = usePresence();
  const [isAnimatedIn, setIsAnimatedIn] = useState(false);
  const entryRoute = useSelector(selectEntryRoute);

  const projects = useSelector(selectAllProjects());
  const project = useSelector(selectProjectBySlug(slug));

  const history = useHistory();
  const controls = useAnimation();

  const preloadStatus = useSelector(selectPreloadStatus);

  const animateOut = useCallback(
    async () => {
      await controls.start(WIPE_LOADING_STATES.OUT);
      safeToRemove();
    },
    [controls, safeToRemove]
  );

  const animateIn = useCallback(
    async () => {
      await controls.start(WIPE_LOADING_STATES.IN);
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
        setIsAnimatedIn(true);
      }, 0);
    },
    [controls]
  );

  useEffect(() => {
    if (preloadStatus === 'idle' && (history.action === 'PUSH' || entryRoute.length === 1)) {
      console.log('IDLE TRANSIITON');
      animateIn();
    }
  }, [preloadStatus, controls, animateIn, history.action, entryRoute])

  useEffect(() => {
    if (!isPresent) {
      if (history.action === 'PUSH') {
        animateOut();
      } else {
        safeToRemove();
      }
    }
  }, [isPresent, safeToRemove, animateOut, history.action]);

  function renderWorkSamples() {
    console.log(project);
    return project.workSamples.map((sample, i) => {
      return <WorkSample
        bgColor={sample.sampleType === 'full_bleed' ? project.backgroundColor.hex : '#000'}
        key={sample.id}
        index={i}
        format={sample.sampleType}
        sample={sample.sampleAsset}
      />
    })
  }

  function compileIntroProps() {
    const p = projects.find(p => p.slug === slug);
    const { title, workCategories: categories, client, year, description, responsibility, liveLink: link } = p;

    return {
      title,
      categories,
      client,
      year,
      description,
      responsibility,
      link
    };
  }


  if (!projects) {
    return false;
  } else {
    return (
      <div className="bgSwitcher" style={{ backgroundColor: project ? project.backgroundColor.hex : 'transparent', transform: (isAnimatedIn || history.action !== 'PUSH') ? 'none' : `translateY(${document.documentElement.scrollTop}px)` }}>
        <Nav modules={[
          {
            ref: headerRef,
            navColor: 'black'
          },
          {
            ref: introRef,
            navColor: 'black'
          },
          {
            ref: workSamplesRef,
            navColor: 'white'
          },
          {
            ref: paginatorRef,
            navColor: 'black'
          },
          {
            ref: endOfPageRef,
            navColor: 'black'
          }
        ]} projects={projects} slug={slug} />
        <motion.div
          style={{ position: 'relative' }}
          variants={WIPE_MOTION_VARIANTS}
          initial={history.action !== 'PUSH' && entryRoute.length > 1 ? WIPE_LOADING_STATES.IN : WIPE_LOADING_STATES.IDLE}
          animate={controls}>
          <Header
            ref={headerRef}
            modules={[
              {
                ref: introRef,
                navColor: 'black'
              },
              {
                ref: workSamplesRef,
                navColor: 'white'
              },
              {
                ref: paginatorRef,
                navColor: 'black'
              },
              {
                ref: endOfPageRef,
                navColor: 'black'
              }
            ]}
            navColor='black'
            bgColor='white'
            projects={projects}
            slug={slug} />
          <Intro ref={introRef} {...compileIntroProps()} />
          <div ref={workSamplesRef} className={styles.workSamplesWrapper}>
            {renderWorkSamples()}
          </div>
          <Paginator
            ref={paginatorRef}
            projects={projects}
            slug={slug} />
          <EndOfPage ref={endOfPageRef} />
        </motion.div>
      </div>
    );
  }
}

export default WorkDetail;
