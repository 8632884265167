import { createSlice } from '@reduxjs/toolkit';

const REDUCER_NAME = 'app';

export const appSlice = createSlice({
  name: REDUCER_NAME,
  initialState: {
    entryRoute: [],
    homeHoverItem: undefined,
    isTouch: ('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0),
    preloadStatus: 'loading'
  },
  reducers: {
    setHomeHoverItem: (state, action) => {
      state.homeHoverItem = action.payload;
    },
    setEntryRoute: (state, action) => {
      state.entryRoute = [...state.entryRoute, action.payload];
    },
    setPreloadStatus: (state, action) => {
      state.preloadStatus = action.payload;
    },
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectHomeHoverItem = state => state[REDUCER_NAME].homeHoverItem;
export const selectIsTouch = state => state[REDUCER_NAME].isTouch;
export const selectEntryRoute = state => state[REDUCER_NAME].entryRoute;
export const selectPreloadStatus = state => state[REDUCER_NAME].preloadStatus;

// Extract the action creators object and the reducer
const { actions, reducer } = appSlice;
// Extract and export each action creator by name
export const { setHomeHoverItem, setPreloadStatus, setEntryRoute } = actions
// Export the reducer, either as a default or named export
export default reducer
