import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './Intro.module.scss';

const Intro = React.forwardRef((props, ref) => {
  const breakpoints = useSelector(state => state.browser.is);
  let breakpoint = Object.keys(breakpoints).find(key => breakpoints[key] === true);

  function renderCategories() {
    return props.categories.map((c, i) => {
      return <li key={`${c.name}-${i}`}>{c.replace(/_/g, ' ')}</li>;
    });
  }

  return (
    <div ref={ref} className={styles.introModule} style={props.home ? { backgroundColor: "#000" } : {}}>
      <div className={styles.introContainer}>
        <div className={styles.introRow}>
          {props.home ? (
            <div className={styles.introHomeHeadline} dangerouslySetInnerHTML={{ __html: props.statement }} />
          ) : (
            <React.Fragment>
              {breakpoint !== 'small' &&
                <div className={styles.introProjectCategories}>
                  <h2>{props.title}</h2>
                  <ul className={styles.introCategoryList}>
                    {renderCategories()}
                  </ul>
                </div>
              }
              <div className={styles.introProjectDetails}>
                <span className={styles.introProjectDetailsHorizontalLine}></span>
                <div className={styles.introProjectDetailsHeader}>
                  <div className={styles.introProjectDetailsResponsibility}>
                    {props.responsibility}
                  </div>
                  <div className={styles.introProjectDetailsClient}>
                    {props.client}
                  </div>
                  <div className={styles.introProjectDetailsYear}>
                    {props.year}
                  </div>
                </div>
                <div className={styles.introProjectDescription}>
                  <p dangerouslySetInnerHTML={{ __html: props.description.html }} />
                </div>
                <div className={styles.introProjectDetailsFooter}>
                  {props.link &&
                    <div className={styles.introProjectLink}>
                      <a href={props.link} target="_blank"><span></span> View Site</a>
                    </div>
                  }
                  {breakpoint === 'small' &&
                    <div className={styles.introCategoryListFooter}>
                      <ul className={styles.introCategoryList}>
                        {renderCategories()}
                      </ul>
                    </div>
                  }
                </div>
                <span className={styles.introProjectDetailsHorizontalLine}></span>
              </div>
            </React.Fragment>
          )
          }
        </div>
      </div>
    </div>
  );
})

export default Intro;
