import React, { useRef } from 'react';
import styles from './Header.module.scss';
import Cover from 'features/shared/Cover';
import { use100vh } from 'react-div-100vh'

const Header = React.forwardRef((props, ref) => {
  const headerHeight = use100vh();

  function renderWorkDetailCover() {
    let currentProject;
    props.projects.find((p, i) => {
      if (p.slug === props.slug) {
        currentProject = {
          data: p,
          index: i
        }

        return true;
      }

      return false;
    });

    //       backdrop={currentProject.data.coverBackgroundImage.url}

    let aspectRatio = 'landscape';
    if (
      currentProject.index === 0 ||
      currentProject.index === 3 ||
      currentProject.index === 4 ||
      currentProject.index === 7 ||
      currentProject.index === 8) {
      aspectRatio = 'portrait';
    }

    return <Cover
      hero={currentProject.data.coverHeroImage.url}
      title={currentProject.data.title}
      textColor={'black'}
      aspectRatio={aspectRatio}
      breadcrumb={`/0${currentProject.index + 1}`}
      alignBottom={false}
    />
  }

  function getHomeImages() {
    return props.projects.map((p, i) => {
      let aspectRatio = 'landscape';
      if (
        i === 0 ||
        i === 3 ||
        i === 4 ||
        i === 7 ||
        i === 8) {
        aspectRatio = 'portrait';
      }

      return {
        url: p.coverHeroImage.url,
        aspectRatio: aspectRatio
      }
    });
  }

  return (
    <header ref={ref} style={{ height: headerHeight, backgroundColor: props.slug ? 'transparent' : '#000' }} className={styles.header}>
      {props.slug ? (
        renderWorkDetailCover()
      ) : (
        <Cover
          home={props.home ? props.home : false}
          preloadStatus={props.home ? props.preloadStatus : undefined}
          firstVisitOnHome={props.firstVisitOnHome}
          backdrop={props.backdrop ? props.backdrop : ''}
          hero={''}
          aspectRatio={'portrait'}
          title={props.title ? props.title : ''}
          breadcrumb={''}
          homeImages={getHomeImages()}
          alignBottom={true}
        />
      )}
    </header>
  );
});

export default Header;
