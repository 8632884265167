export const PAGETRANSITION_DURATION_IN_MS = 1000;
export const PAGETRANSITION_DURATION_IN_SEC = 1;
export const WIPE_DURATION_IN_SEC = 0.6;

export const WIPE_EASING = [0.770, 0.000, 0.175, 1.000];

export const WIPE_LOADING_STATES = {
  IN: "in",
  OUT: "out",
  IDLE: "idle"
};

export const WIPE_MOTION_VARIANTS = {
  [WIPE_LOADING_STATES.IDLE]: {
    y: "30vh",
    transition: {
      duration: 0.5,
      delay: 0,
      ease: WIPE_EASING
    }
  },
  [WIPE_LOADING_STATES.IN]: {
    y: 0,
    transition: {
      duration: 0.6,
      delay: 0,
      ease: WIPE_EASING
    }
  },
  [WIPE_LOADING_STATES.OUT]: {
    y: "-80vh",
    transition: {
      duration: 0.8,
      delay: 0,
      ease: WIPE_EASING
    }
  }
}

export const NAV_ANIMATION_STATES = {
  HIDE: "hide",
  SHOW: "show"
};

export const NAV_ANIMATION_VARIANTS = {
  [NAV_ANIMATION_STATES.SHOW]: {
    opacity: 1,
    transition: {
      duration: 0.9,
      delay: 0.3,
      ease: [0.25, 0.1, 0.25, 1]
    }
  },
  [NAV_ANIMATION_STATES.HIDE]: {
    opacity: 0,
    transition: {
      duration: 0.9,
      delay: 0,
      ease: [0.25, 0.1, 0.25, 1]
    }
  }
}
