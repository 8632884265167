import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { current } from 'immer';
import { request } from 'graphql-request';

// https://immerjs.github.io/immer/docs/update-patterns

const REDUCER_NAME = 'api';
const API_ENDPOINT = 'https://api-us-east-1.graphcms.com/v2/ckjky8dar454m01z98her78es/master';

export const fetchContent = createAsyncThunk(
  `${REDUCER_NAME}/fetchContent`,
  async (_, thunkAPI) => {
    // const response = await axios.get('https://5ff3bd2c16cf4f0017c1f60b.mockapi.io/api/v1/work');
    const { pages } = await request(
      API_ENDPOINT,
      `
        {
          pages {
            id
            title
            heroImage {
              id
              url
            }
            content {
              html
            }
            awardsRecognitions {
              id
              name
              category
            }
            posts {
              id
              title
              slug
              backgroundColor {
                hex
              }
              coverHeroImage {
                id
                url
                mimeType
              }
              homeGridHero {
                id
                url
                mimeType
              }
              description {
                html
              }
              workSamples {
                id
                sampleAsset {
                  id
                  fileName
                  url
                  mimeType
                }
                sampleType
              }
              client
              workCategories
              responsibility
              year
              liveLink
            }
          }
        }
      `
    );

    return pages;
  }
)

export const apiSlice = createSlice({
  name: REDUCER_NAME,
  initialState: {
    loading: false,
    data: undefined,
    config: {
      transitionDuration: 1000,
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [fetchContent.pending]: (state, action) => {
      // Add user to the state array
      console.log('fetchContent.pending');
      state.loading = true;
    },
    [fetchContent.fulfilled]: (state, action) => {
      // Add user to the state array
      console.log(current(state));
      console.log('fetchContent.fulfilled');
      state.loading = false;
      state.data = action.payload;
    }
  }
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectConfig = state => state[REDUCER_NAME].config;

export const selectData = state => state[REDUCER_NAME].data;

export const selectProjectBySlug = slug => {
  return createSelector(
    selectData,
    data => {
      let p = data ? data.find(page => page.title === 'portfolio') : null;
      if (p) {
        return p.posts.find(post => post.slug === slug);
      }

      return p ? p : null;
    }
  )
}

export const selectAboutData = slug => {
  return createSelector(
    selectData,
    data => {
      let p = data ? data.find(page => page.title === 'about') : null;
      return p ? p : null;
    }
  )
}

export const selectAllProjects = () => {
  return createSelector(
    selectData,
    data => {
      let p = data ? data.find(page => page.title === 'portfolio') : null;
      if (p) {
        return p.posts;
      }

      return p ? p : null;
    }
  )
}

// Extract the action creators object and the reducer
const { reducer } = apiSlice;
// Export the reducer, either as a default or named export
export default reducer
